.footer-tagline {
  margin-bottom: 0 !important;
}

#chat-button {
  z-index: 2000 !important;
}

.osano-cm-widget {
  display: none;
}